import Vue from "vue"
import { cloneDeep } from "~/helpers/Functions"

export default function (ctx, inject) {
    ctx.$axios.setHeader("Accept-Language", ctx.i18n.locale)
    ctx.$moment.locale(ctx.i18n.localeProperties.iso)

    ctx.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale) => {
        ctx.$axios.setHeader("Accept-Language", newLocale)
        ctx.$moment.locale(newLocale)
    }

    ctx.i18n.onLanguageSwitched = async (oldLocale, newLocale) => {
        // Store language on user profile on backend
        if (ctx.store.getters["authentication/authenticated"]) {
            await Promise.all([ctx.store.dispatch("api/customer/get"), ctx.store.dispatch("api/languages/get")])
            ctx.store.dispatch("api/customer/patch", {
                url: ctx.store.state.api.customer.url,
                prefered_language: ctx.store.state.api.languages.find(x => x.iso_code.toLowerCase() === ctx.i18n.localeProperties.iso.toLowerCase()).url,
            })
        }

        // Resetting store excluding some keys
        // const excludedKeys = ["currentFlow", "languageUpdatedByUser"]
        // for (let key in ctx.store.state.api) if (!excludedKeys.includes(key)) ctx.store.commit(`api/${key}/reset`)
    }

    inject(
        "localization",
        new Vue({
            computed: {
                languages() {
                    const isoSet = new Set($nuxt.$i18n.locales.map(locale => locale.iso.toLowerCase()))
                    return ctx.store.state.api.languages.filter(item => isoSet.has(item.iso_code.toLowerCase()))
                },
                countries() {
                    return ctx.store.state.api.countries
                },
                currentLanguage() {
                    return (
                        ctx.store.state.api.languages.find(item => {
                            switch (ctx.i18n.localeProperties.iso.toLowerCase()) {
                                case "nl-be":
                                    return "be-nl" === item.iso_code.toLowerCase() || "nl-be" === item.iso_code.toLowerCase()
                                case "fr-be":
                                    return "be-fr" === item.iso_code.toLowerCase() || "fr-be" === item.iso_code.toLowerCase()
                                default:
                                    return ctx.i18n.localeProperties.iso.toLowerCase() === item.iso_code.toLowerCase()
                            }
                        }) || {}
                    )
                },
                currentCountry() {
                    const belgium = this.countries.find(item => item.short_name.toUpperCase() === "BE")
                    if (window.location.href.includes("localhost")) return belgium
                    return this.countries.find(item => window.location.href.includes(item.frontend_domain_name)) || belgium
                },
                defaultLanguage() {
                    if (!this.currentCountry) return this.languages[0]
                    return this.languages.find(x => x.url === this.currentCountry.default_language)
                },
                locale() {
                    return ctx.i18n.localeProperties.iso.split("-")[0]
                },
                support_email() {
                    if (!this.currentCountry || !this.currentLanguage) return ""
                    if (this.currentCountry.short_name === "BE" && this.currentLanguage.iso_code.toLowerCase().includes("fr")) return "bonjour@justrussel.com"
                    return this.currentCountry.support_email
                },
                shortDate() {
                    return { en: "DD/MM/YYYY", fr: "DD-MM-YYYY", nl: "DD-MM-YYYY", de: "DD.MM.YYYY" }[this.locale]
                },
                longDate() {
                    return { en: "dddd DD MMMM", fr: "dddd DD MMMM", nl: "dddd DD MMMM", de: "dddd, [den] DD MMMM" }[this.locale]
                },
                longDateWithYear() {
                    return { en: "MMMM DD, YYYY", fr: "DD MMMM, YYYY", nl: "DD MMMM, YYYY", de: "DD MMMM, YYYY" }[this.locale]
                },
            },
            methods: {
                changeCountry(country) {
                    let query = Object.entries(ctx.route.query)
                        .map(([key, value]) => key + "=" + encodeURIComponent(value))
                        .join("&")
                    if (query) query = "?" + query
                    window.location.assign(`http://${country.frontend_domain_name}/${ctx.route.path.split("/").slice(2).join("/")}${query}`)
                },
                formatAddress(address) {
                    const country = this.countries.find(x => x.short_name === address.country)
                    if (country?.short_name === "FR") {
                        return [`${address.number}${address.bus} ${address.street}`, `${address.postal_code} ${address.city}, ${country?.name}`]
                    } else {
                        return [`${address.street} ${address.number}${address.bus}`, `${address.postal_code} ${address.city}, ${country?.name}`]
                    }
                },
            },
        }),
    )
}
